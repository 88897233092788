<template>
  <section class="solution">
    <div class="banner">
      <div class="wp">
        <p class="tip">即刻聚合平台</p>
        <h3 class="title">SAAS级别的聚合SDK</h3>
        <h3 class="title">商业化解决方案</h3>
        <p class="sub-title top">
          一站式接入国内八大主流广告变现平台，中立、稳定、高效的第三方广
        </p>
        <p class="sub-title">告聚合“多合一”SDK，帮助实现开发者收益最大化</p>
        <span class="more" @click="onJumpToConnect">
          立即开始
          <span class="bg"></span>
        </span>
      </div>
    </div>
    <div class="solution-way">
      <div class="wp">
        <p class="title">开发者收入提升方案</p>
        <ul class="way-list">
          <li v-for="item in wayList" :key="item.key">
            <img :src="item.image" class="image" />
            <p class="link-content">
              <span class="link" @click="onScroll(item.link)">
                {{ item.value }}
              </span>
              <img :src="rightIcon" class="link-image" />
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div id="earning" class="earning">
      <div class="earning-main">
        <div class="content">
          <p class="tip">收益提升</p>
          <h4 class="header">预算搭配+特殊权限</h4>
          <div class="detail">
            <div class="left item">
              <p class="title">丰富多样的广告预算</p>
              <div class="image-bg">
                <div class="top-box box">
                  <div class="top-box-bg"></div>
                  <span class="normal">10*直客</span>
                  <p class="normal"><span class="high">SDK</span>广告源</p>
                </div>
                <div class="center-box box">
                  <div class="center-box-bg"></div>
                  <span class="high">直客</span>
                  <p class="normal">电商/资讯/视频/游戏/厂商</p>
                </div>
                <div class="bottom-box box">
                  <div class="bottom-box-bg"></div>
                  <p class="normal">UBiX<span class="high">自有</span></p>
                  <span class="normal">品牌/效果</span>
                </div>
              </div>
            </div>
            <div class="middle">
              <div class="main-word">
                <p>收益最大化</p>
                <p>资源共享</p>
                <p>最佳上游调配</p>
              </div>
              <img :src="transformIcon" class="image" />
              <p class="desc">
                专业SDK上游拓展团队针对不同媒体需求匹配更适合的广告资源与特殊权限获取
              </p>
            </div>
            <div class="right item">
              <p class="title">高价值账户权限</p>
              <div class="image-bg">
                <div class="rhombus rhombus-top">
                  <p>Biding/保价</p>
                  <p>政策合规/素材审核</p>
                  <p>自渲染等权限</p>
                </div>
                <div class="rhombus rhombus-bottom">
                  <p>大促节点</p>
                  <p>高价预算倾斜</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="strategy" class="strategy">
      <div class="wp">
        <p class="tip">收益提升</p>
        <h3 class="title">运营策略展示</h3>
        <ul class="strategy-list">
          <li v-for="item in strategyList" :key="item.key" class="list-item">
            <div class="item-content">
              <p class="item-title">{{ item.title }}</p>
              <span class="item-desc">{{ item.desc }}</span>
            </div>
            <img :src="item.image" class="image" />
          </li>
        </ul>
      </div>
    </div>
    <div id="mode" class="mode">
      <div class="wp">
        <p class="tip">收益提升</p>
        <h3 class="title">多种合作模式</h3>
        <ul class="mode-list">
          <li v-for="item in modeList" :key="item.key" class="list-item">
            <div class="item-content">
              <p class="item-title">
                {{ item.title }}
                <img v-if="item.star" :src="starIcon" class="star-image" />
              </p>
              <span v-if="item.descFirstLine" class="item-desc">
                {{ item.descFirstLine }}
              </span>
              <span v-if="item.descSecondLine" class="item-desc">
                {{ item.descSecondLine }}
              </span>
            </div>
            <img :src="item.image" class="image" />
          </li>
        </ul>
      </div>
    </div>
    <div id="demo" class="demo">
      <div class="wp">
        <h3 class="title">合作案例</h3>
        <ul class="demo-list">
          <li
            v-for="(item, index) in demoList"
            :key="item.key"
            class="list-item"
            :class="`list-item-demo${index} list-item-demo-common`"
            @mouseenter="onMouseEnter(index)"
            @mouseleave="onMouseLeave(index)"
          >
            <!-- <img :src="item.image" class="image" /> -->
            <div class="item-content-bg">
              <div class="item-content">
                <p class="item-title item-title-top">
                  {{ item.title }}
                </p>
                <ul class="sub-item-list">
                  <li
                    v-for="subItem in item.numList"
                    :key="subItem.key"
                    class="sub-item"
                  >
                    <span v-if="subItem.tip" class="sub-item-tip">
                      {{ subItem.tip }}
                    </span>
                    <p class="sub-item-title">
                      <span>{{ subItem.value }}</span>
                      <img :src="greenUpIcon" class="green-up-icon" />
                    </p>
                  </li>
                </ul>
                <p class="item-title item-title-down">
                  {{ item.title }}
                </p>
                <p class="item-desc">
                  {{ item.desc }}
                </p>
                <p class="item-title item-title-intro">
                  {{ item.title }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="cooperation">
      <div class="cooperation-text">
        <div class="wp">
          <h3 class="title">与我们合作 最大化您的广告商业化收入</h3>
          <span class="more" @click="onJumpToConnect">立即开始</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import rightIcon from '@/assets/home/rightIcon.png'
import userIcon from '@/assets/solution/userIcon.png'
import moneyIcon from '@/assets/solution/moneyIcon.png'
import upIcon from '@/assets/solution/upIcon.png'
import saveIcon from '@/assets/solution/saveIcon.png'
import dataIcon from '@/assets/solution/dataIcon.jpg'
import abTestIcon from '@/assets/solution/abTestIcon.png'
import flowLayerIcon from '@/assets/solution/flowLayerIcon.png'
import transformIcon from '@/assets/solution/transformIcon.png'
import numOneIcon from '@/assets/solution/numOneIcon.png'
import numTwoIcon from '@/assets/solution/numTwoIcon.png'
import numThreeIcon from '@/assets/solution/numThreeIconer.png'
import numFourIcon from '@/assets/solution/numFourIcon.png'
import starIcon from '@/assets/solution/starIcon.png'
import serverBiddingIcon from '@/assets/solution/serverBiddingIcon.png'
import sdkDemoIcon from '@/assets/solution/sdkDemoIcon.png'
import greenUpIcon from '@/assets/solution/greenUpIcon.png'
import { smoothScroll } from '@/utils/index'
import { getSource } from '@/utils'

const router = useRouter()
const route = useRoute()
const wayList = [
  {
    key: '1',
    image: upIcon,
    value: '预算搭配',
    link: '#earning',
  },
  {
    key: '2',
    image: moneyIcon,
    value: '特殊权限',
    link: '#earning',
  },
  {
    key: '3',
    image: saveIcon,
    value: '运营策略',
    link: '#strategy',
  },
  {
    key: '4',
    image: userIcon,
    value: '合作模式',
    link: '#mode',
  },
]

const strategyList = [
  {
    key: '1',
    image: flowLayerIcon,
    title: '流量分层分级',
    desc: '在遵循个人信息保护法的基础上，采集合规数据进行高价值人群的受众定向和分层，实现精细化运营',
  },
  {
    key: '2',
    image: abTestIcon,
    title: 'A/B Test',
    desc: 'A/B测试作为优化方式，进行策略实验；全流量多种策略同步实施，对比收益数据；多种策略变现效果对比',
  },
  {
    key: '3',
    image: dataIcon,
    title: '数据报表管理',
    desc: '数据全面呈现，总览变现数据，多维度展示反映流量变现状况；40余项指标，详尽剖析变现的过程损耗，大幅提升广告填充展示率',
  },
]

const modeList = [
  {
    key: '1',
    image: numOneIcon,
    title: '独家代运营合作',
    star: true,
    descFirstLine: 'App商业化全包/ARPU、RCPM值',
    descSecondLine: '分渠道买卖联动/广告位独占式包量',
  },
  {
    key: '2',
    image: numTwoIcon,
    title: '头层Bidding保收入合作',
    star: true,
    descFirstLine: '支持串/并行层级中的保量保填充合作',
  },
  {
    key: '3',
    image: numThreeIcon,
    title: 'PD/RTB竞价合作',
    star: false,
    descFirstLine: '支持保价及透传竞价模式',
    descSecondLine: '对单/多层流量进行广告填充',
  },
  {
    key: '4',
    image: numFourIcon,
    title: 'SAAS平台技术服务',
    star: false,
    descFirstLine: '提供聚合流量交易平台工具',
    descSecondLine: '对广告资源统一售卖管理 提高变现效率',
  },
]

const demoList = [
  {
    key: '1',
    image: serverBiddingIcon,
    title: 'Server Bidding SDK案例',
    desc: '利用领先的服务器端Bidding逻辑，实现充分及高效的竞价；同时保证优质素材的精准筛选，使开发者优质的头层流量达到最高的价值体现，以达成规模化提升开发者整体收益的合作目标',
    numList: [
      {
        key: '1',
        value: '552%',
        tip: 'IOS 日均 RCPM',
      },
      {
        key: '2',
        value: '102%',
        tip: 'Android 日均 RCPM',
      },
    ],
  },
  {
    key: '2',
    image: sdkDemoIcon,
    title: '独家代运营SDK合作案例',
    desc: '凭借独家代运营的全包量合作模式，可发挥UBiX独有的上游账户权限优势，输出行之有效的运营策略，在产品和算法的强大赋能下，对UBiX的优势资源加以整合，为开发者的商业化痛点提供量身定制的解决方案，以达成开发者收益最大化的广告变现目标',
    numList: [
      {
        key: '1',
        value: '30%',
        tip: 'ARPU',
      },
      {
        key: '2',
        value: '45%',
        tip: '人均展示次数',
      },
      {
        key: '3',
        value: '52%',
        tip: '开屏 eCPM',
      },
    ],
  },
]

const onJumpToConnect = () => {
  const source = getSource(route.path)
  router.push(`/contact?source=${source}`)
}

const onMouseEnter = (index: number) => {
  for (let i = 0; i < demoList.length; i++) {
    const demoObj = document.getElementsByClassName(
      `list-item-demo${i}`,
    )[0] as HTMLElement
    if (index === i) {
      demoObj.style.width = '845px'
      const contentTitleObj = demoObj.getElementsByClassName(
        `item-title-top`,
      )[0] as HTMLElement
      contentTitleObj.style.height = '0'
    } else {
      demoObj.style.width = '261px'
      const contentListObj = demoObj.getElementsByClassName(
        `sub-item-list`,
      )[0] as HTMLElement
      contentListObj.style.height = '0'
      // contentListObj.style.display = 'none'
      const contentDescObj = demoObj.getElementsByClassName(
        `item-desc`,
      )[0] as HTMLElement
      contentDescObj.style.display = 'none'
      const contentTitleObj = demoObj.getElementsByClassName(
        `item-title-top`,
      )[0] as HTMLElement
      contentTitleObj.style.height = '0'
      const contentTitleIntroObj = demoObj.getElementsByClassName(
        `item-title-intro`,
      )[0] as HTMLElement
      contentTitleIntroObj.style.display = 'block'
    }
  }
}

const onMouseLeave = (index: number) => {
  for (let i = 0; i < demoList.length; i++) {
    const demoObj = document.getElementsByClassName(
      `list-item-demo${i}`,
    )[0] as HTMLElement
    demoObj.style.width = '553px'
    const contentListObj = demoObj.getElementsByClassName(
      `sub-item-list`,
    )[0] as HTMLElement
    contentListObj.style.height = '78px'
    // contentListObj.style.display = 'flex'
    const contentDescObj = demoObj.getElementsByClassName(
      `item-desc`,
    )[0] as HTMLElement
    contentDescObj.style.display = 'block'
    const contentTitleObj = demoObj.getElementsByClassName(
      `item-title-top`,
    )[0] as HTMLElement
    contentTitleObj.style.height = '45px'
    const contentTitleIntroObj = demoObj.getElementsByClassName(
        `item-title-intro`,
      )[0] as HTMLElement
      contentTitleIntroObj.style.display = 'none'
  }
}

const onScroll = (target: string) => {
  smoothScroll(target)
}
</script>

<style lang="scss" scoped>
@mixin bgShadow {
  background: rgba(39, 39, 39, 0.83);
  border-radius: 10px;
  filter: blur(54px);
}
.solution {
  .banner {
    background-color: $colorBgWhite;
    .wp {
      @include width-1440;
      text-align: center;
      padding-top: 120px;
      padding-bottom: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tip {
        height: 34px;
        font-size: $titleFont1;
        color: $colorActive;
        line-height: 34px;
        margin-bottom: 8px;
      }
      .title {
        @include title-black;
      }
      .sub-title {
        @include sub-title;
        height: 40px;
        &.top {
          margin-top: 32px;
        }
      }
      .more {
        margin-top: 56px;
        @include start-btn;
        .bg {
          @include start-btn-bg;
        }
      }
    }
  }
  .solution-way {
    background-color: $colorBgWhite;
    .wp {
      @include width-1440;
      text-align: center;
      padding-top: 84px;
      padding-bottom: 84px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        @include title-black;
        margin-bottom: 72px;
      }
      .way-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .image {
          width: 70px;
          height: 60px;
        }
        .link-content {
          height: 24px;
          margin-top: 25px;
          display: flex;
          align-items: center;
          .link {
            font-size: $titleFont1;
            font-weight: $mediumWeight;
            color: $colorActive;
            line-height: 1;
            cursor: pointer;
            border-bottom: 3px solid transparent;
            &:hover {
              border-bottom: 3px solid $colorActive;
            }
          }
          .link-image {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
  .earning {
    position: relative;
    height: 756px;
    background-color: $colorBgWhite;
    background: url(@/assets/solution/earningBg.png) no-repeat center;
    background-size: cover;
    .earning-main {
      @include width-1440;
      height: 100%;
      padding-top: 84px;
      padding-bottom: 77px;
      .content {
        width: 100%;
        height: 100%;
        text-align: center;
        .tip {
          height: 40px;
          font-size: $titleFont1;
          color: $colorGreen;
          line-height: 40px;
          margin-bottom: 8px;
        }
        .header {
          @include title-white;
          margin-bottom: 56px;
        }
        .detail {
          display: flex;
          justify-content: space-between;
          padding-left: 66px;
          padding-right: 74px;
          .item {
            .title {
              height: 28px;
              font-size: $font5;
              font-weight: $mediumWeight;
              margin-bottom: 36px;
              text-align: left;
              color: $colorWhite1;
            }
            .image-bg {
              width: 194px;
              height: 349px;
              position: relative;
              .box {
                background: $colorBgBlack;
                box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.25);
                border-radius: $border16;
                padding: 15px 0;
                position: absolute;
                .normal {
                  font-size: $font2;
                  color: $colorWhite2;
                  line-height: 20px;
                }
                .high {
                  line-height: 22px;
                  color: $colorWhite1;
                  font-size: $font3;
                  font-weight: $mediumWeight;
                }
              }
              .top-box {
                width: 125px;
                height: 77px;
                left: -22px;
                top: 60px;
                text-align: left;
                padding-left: 24px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .top-box-bg {
                  position: absolute;
                  top: 53px;
                  left: 10px;
                  width: 105px;
                  height: 45px;
                  @include bgShadow;
                }
              }
              .center-box {
                left: 77px;
                top: 144px;
                width: 187px;
                height: 79px;
                display: flex;
                align-items: center;
                padding-left: 24px;
                .center-box-bg {
                  position: absolute;
                  top: 47px;
                  left: 10px;
                  width: 167px;
                  height: 69px;
                  @include bgShadow;
                }
                .normal {
                  width: 106px;
                  text-align: left;
                  margin-left: 5px;
                }
              }
              .bottom-box {
                left: -22px;
                bottom: 45px;
                width: 115px;
                height: 75px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .bottom-box-bg {
                  position: absolute;
                  top: 51px;
                  left: 10px;
                  width: 95px;
                  height: 45px;
                  @include bgShadow;
                }
              }
            }
          }
          .left {
            width: 286px;
            .title {
              margin-left: 5px;
            }
            .image-bg {
              background: url(@/assets/solution/phoneIcon.png) no-repeat center;
              background-size: contain;
            }
          }
          .middle {
            width: 429px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .main-word {
              margin-top: 64px;
              font-size: 32px;
              font-weight: $mediumWeight;
              color: $colorWhite1;
              line-height: 45px;
            }
            .image {
              width: 429px;
              height: 80px;
              margin-top: 39px;
              margin-bottom: 32px;
            }
            .desc {
              width: 370px;
              font-size: $font4;
              color: $colorWhite2;
              line-height: 25px;
            }
          }
          .right {
            width: 252px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .image-bg {
              background: #919191;
              border-radius: $border24;
              .rhombus {
                width: 234px;
                height: 186px;
                position: absolute;
                left: -20px;
                font-size: $font3;
                font-weight: $mediumWeight;
                color: $colorWhite1;
                line-height: 22px;
              }
              .rhombus-top {
                top: 20px;
                background: url(@/assets/solution/rhombusLightIcon.png)
                  no-repeat center;
                background-size: contain;
                z-index: 3;
                padding: 60px 30px;
              }
              .rhombus-bottom {
                bottom: 20px;
                background: url(@/assets/solution/rhombusIcon.png) no-repeat
                  center;
                background-size: contain;
                padding: 75px 30px;
              }
            }
          }
        }
      }
    }
  }

  .strategy {
    background-color: $colorBgWhite;
    .wp {
      @include width-1440;
      text-align: center;
      padding-top: 84px;
      padding-bottom: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tip {
        font-size: $titleFont1;
        color: $colorActive;
        line-height: 40px;
        margin-bottom: 8px;
      }
      .title {
        @include title-black;
        margin-bottom: 48px;
      }
      .strategy-list {
        .list-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;
          .item-content {
            padding: 0 32px;
            display: flex;
            flex-direction: column;
            text-align: left;
            justify-content: center;
            .item-title {
              font-size: 32px;
              font-weight: $mediumWeight;
              color: $colorBlack1;
              line-height: 54px;
              margin-bottom: 12px;
            }
            .item-desc {
              font-size: $font5;
              color: $colorBlack2;
              line-height: 34px;
            }
          }
          .image {
            width: 553px;
            height: 335px;
          }
        }
      }
    }
  }

  .mode {
    background-color: $colorBgWhite;
    .wp {
      @include width-1440;
      text-align: center;
      padding-top: 84px;
      padding-bottom: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tip {
        font-size: $titleFont1;
        color: $colorActive;
        line-height: 40px;
        margin-bottom: 8px;
      }
      .title {
        @include title-black;
        margin-bottom: 48px;
      }
      .mode-list {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        .list-item {
          width: 555px;
          height: 224px;
          padding: 0 32px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;
          background: $colorBgGreyLight;
          box-shadow: 0px 2px 32px 0px rgba(60, 79, 101, 0.16);
          border-radius: $border24;
          opacity: 1;
          border: 1px solid $colorBorder3;
          position: relative;
          .image {
            position: absolute;
            right: 32px;
            top: 51px;
            width: 128px;
            height: 128px;
          }
          .item-content {
            display: flex;
            flex-direction: column;
            text-align: left;
            padding-top: 50px;
            .item-title {
              height: 54px;
              font-size: 32px;
              font-weight: $mediumWeight;
              color: $colorBlack1;
              line-height: 54px;
              margin-bottom: 12px;
              .star-image {
                width: 16px;
                height: 16px;
              }
            }
            .item-desc {
              font-size: $font5;
              color: $colorWord3;
              line-height: 34px;
            }
          }
        }
      }
    }
  }

  .demo {
    background-color: $colorBgWhite;
    .wp {
      @include width-1440;
      text-align: center;
      padding-top: 84px;
      padding-bottom: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tip {
        font-size: $titleFont1;
        color: $colorActive;
        line-height: 40px;
        margin-bottom: 8px;
      }
      .title {
        @include title-black;
        margin-bottom: 48px;
      }
      .demo-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .list-item-demo0 {
          background: url(@/assets/solution/serverBiddingIcon.png) no-repeat
            center;
          background-size: cover;
          &:hover {
            width: 845px !important;
            .item-content-bg {
              .item-content {
                .item-desc {
                  height: auto;
                }
                .item-title-down {
                  height: 45px;
                }
              }
            }
          }
        }
        .list-item-demo1 {
          background: url(@/assets/solution/sdkDemoIcon.png) no-repeat center;
          background-size: cover;
          &:hover {
            width: 845px !important;
            .item-content-bg {
              .item-content {
                .item-desc {
                  height: auto;
                }
                .item-title-down {
                  height: 45px;
                }
              }
            }
          }
        }
        .list-item {
          width: 553px;
          height: 414px;
          display: flex;
          justify-content: space-between;
          border-radius: $border24;
          opacity: 1;
          position: relative;
          transition: width 0.4s;
          cursor: pointer;
          .item-content-bg {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .item-content {
              width: 100%;
              padding: 16px 24px;
              display: flex;
              flex-direction: column;
              text-align: left;
              justify-content: flex-end;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                #000000 100%
              );
              border-radius: $border24;
              .item-title {
                font-size: 32px;
                font-weight: $mediumWeight;
                color: $colorWhite1;
                line-height: 45px;
                overflow: hidden;
              }
              .item-title-top {
                height: 45px;
              }
              .item-title-down {
                height: 0;
              }
              .item-title-intro {
                width: 200px;
                overflow: hidden;
                display: none;
              }
              .sub-item-list {
                width: 100%;
                margin-top: 4px;
                overflow: hidden;
                .sub-item {
                  margin-right: 40px;
                  float: left;
                  .sub-item-tip {
                    height: 22px;
                    font-size: $font3;
                    color: $colorWhite2;
                  }
                  .sub-item-title {
                    font-size: $titleFont2;
                    color: $colorGreen3;
                    height: 50px;
                    line-height: 50px;
                    font-weight: $mediumWeight;
                    .green-up-icon {
                      margin-left: 5px;
                      width: 14px;
                      height: 12px;
                    }
                  }
                }
              }
              .item-desc {
                height: 0;
                font-size: $font3;
                color: $colorWhite2;
                line-height: 26px;
                overflow: hidden;
                width: 798px;
              }
            }
          }
        }
      }
    }
  }
  .cooperation {
    position: relative;
    background-color: $colorBgDark;
    height: 343px;
    box-shadow: inset 0px -1px 0px 0px rgba(217, 217, 217, 0.08);
    .wp {
      @include width-1440;
      display: flex;
      align-items: center;
      flex-direction: column;
      .title {
        @include title-white;
        margin-top: 78px;
        margin-bottom: 52px;
        text-align: center;
      }
      .more {
        @include start-btn;
        color: $colorBlack1;
        background-color: $colorBgWhite;
        &:hover {
          background: $colorHoverBg;
        }
      }
    }
  }
}
</style>
